
<template>
    <div id="register-container">
        <div class="login-content">
            <div class="title">Sign Up</div>
            <div class="login-form">
                <div class="login-form-item">
                    <div class="form-label">First Name</div>
                    <input type="text" v-model="userData.firstName">
                </div>
                <div class="login-form-item">
                    <div class="form-label">Last Name</div>
                    <input type="text" v-model="userData.lastName">
                </div>
                <div class="login-form-item">
                    <div class="form-label">Email</div>
                    <input type="text" v-model="userData.email">
                </div>
                <div class="login-form-item">
                    <div class="form-label">Password</div>
                    <input type="password" v-model="userData.pawssord">
                </div>
                <div class="action-btn"  @click='handleRegister'>Register</div>
                <div class="action-tip">Already have an account?  <router-link to="/account/login">Login</router-link></div>

            </div>
        </div>
    </div>
</template>

<script>
import {register} from '@/api/mall/index.js'
import {mapActions , mapGetters} from 'vuex'
export default {
    data() {
        return {
            userData:{
                firstName:'',
                lastName:'',
                email:'',
                pawssord:''
            }
        }
    },
    methods:{
        handleRegister(){
            register(JSON.stringify(this.userData)).then(res=>{
                if(res.data.code == 0){
                    this.$router.push('/account/login')
                }
            })
        },
        
    }
}
</script>

<style lang="stylus" scoped>
div#register-container
    background #fff
    color #222
    height 100%
    border-top 1px solid #a0a0a0
    .title 
        font-size 1.25rem
        text-align center
        padding-top 2.5rem
        padding-bottom 1.25rem            
    .login-content
        width 22.5rem
        margin: 0 auto
        .form-label 
            font-size .875rem
            margin-bottom .625rem
        .login-form-item
            input
                box-sizing border-box
                width 100%
                height 2.5rem
                line-height 2.5rem
                padding-left .625rem
                border 1px solid #dfdfdf
                margin-bottom .625rem
        .action-btn
            font-size 1rem
            background #000
            text-align center
            color #fff
            height 2.5rem
            line-height 2.5rem
            width 100%
            margin-top 1.25rem
        .action-tip
            font-size .875rem
            text-align center
            margin-top .625rem
            a
                font-weight 600
        
        
</style>
